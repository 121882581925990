import React,{useEffect, useState} from "react";
import API from "../../../utils/API";
import Filter from "../../../components/filter/Filter";
import CSVDownlaod from "../../../components/CsvDownload";

const PixelFilters = ({pixel}) => {

    const [filters,setFilters] = useState(null)
    const [currentFilterTitle,setCurrentFilterTitle] = useState(null)
    const [reRender,setReRender] = useState(true)

    useEffect(() => {
        API.get('/admin/filter/'+pixel.id+'/pixel').then((response) => {
            setFilters(response.data.data)
        });
    }, [pixel.id,reRender])

    return (
        <>
            {currentFilterTitle ?
                <Filter title={currentFilterTitle} type={"pixel"} elementId={pixel.id} onClose={()=>{setCurrentFilterTitle(null);setReRender(!reRender)}}/>
                :
                filters &&
                filters.map((filter, index) => {
                    return (
                        <>
                            <div className="row m-3 border-bottom pb-1" key={"filter_"+index}>
                                <div className="col-2">
                                    <strong>{filter.title}</strong>
                                </div>
                                <div className="col" dangerouslySetInnerHTML={{__html: filter.displayData}}/>
                                <div className="col-auto text-right">
                                    <button className="btn btn-primary" onClick={()=>{setCurrentFilterTitle(filter.title)}}>Set up</button>
                                </div>
                            </div>
                        </>
                    )
                })
            }
            {!currentFilterTitle && 
                <div className="row m-3 justify-content-end align-items-center">
                    <div className="pr-1">
                        <strong>Zip Code List</strong>
                    </div>
                    <CSVDownlaod
                        url="/admin/pixel/getziplist"
                        filename="PixelZipCodeFilter"
                        filtration={{ pixelId: pixel.id }}
                    />
                </div>
            }
        </>
    )
}

export default PixelFilters
